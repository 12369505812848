@font-face {
  font-family: EffraMd;
  src: url("shared/fonts/effra/Effra_Trial_Md.ttf") format('truetype');
}

@font-face {
  font-family: Effra2;
  src: url("shared/fonts/effra/Effra_W_Rg.woff2") format('woff2');
}

@font-face {
  font-family: Effra;
  src: url("shared/fonts/effra/Effra_W_Rg.woff") format('woff');
}

@font-face {
    font-family: "Fira sans";
    src: url("shared/fonts/fira-sans/FiraSans-Regular.ttf") format('truetype');
  }

@font-face {
    font-family: "Fira sans bold";
    src: url("shared/fonts/fira-sans/FiraSans-Bold.ttf") format('truetype');
}

@import "@aws-amplify/ui-react/styles.css";

:root [data-amplify-theme] {
  --amplify-colors-brand-primary-10: var(--amplify-colors-green-10);
  --amplify-colors-brand-primary-20: var(--amplify-colors-green-20);
  --amplify-colors-brand-primary-40: var(--amplify-colors-green-40);
  --amplify-colors-brand-primary-60: var(--amplify-colors-green-60);
  --amplify-colors-brand-primary-80: var(--amplify-colors-green-80);
  --amplify-colors-brand-primary-90: var(--amplify-colors-green-90);
  --amplify-colors-brand-primary-100: var(--amplify-colors-green-100);
  --amplify-colors-brand-secondary-10: var(--amplify-colors-neutral-10);
  --amplify-colors-brand-secondary-20: var(--amplify-colors-neutral-20);
  --amplify-colors-brand-secondary-40: var(--amplify-colors-neutral-40);
  --amplify-colors-brand-secondary-60: var(--amplify-colors-neutral-60);
  --amplify-colors-brand-secondary-80: var(--amplify-colors-neutral-80);
  --amplify-colors-brand-secondary-90: var(--amplify-colors-neutral-90);
  --amplify-colors-brand-secondary-100: var(--amplify-colors-neutral-100);
  --amplify-colors-radii-small: 0;
  --amplify-colors-radii-medium: 2px;
  --amplify-colors-radii-large: 4px;
  --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
  --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
  --amplify-colors-background-secondary: var(--amplify-colors-brand-primary-10);

  --amplify-components-tabs-item-border-color: var(--amplify-colors-neutral-60);
  --amplify-components-authenticator-modal-background-color: #F0F1F3;
  --amplify-components-image-max-width: 50%;
  --amplify-components-authenticator-router-border-width: 0px;

  --amplify-radii-small: 5px;
  --amplify-radii-medium: 10px;
  --amplify-radii-large: 15px;
  --amplify-space-small: 1rem;
  --amplify-space-medium: 1.5rem;
  --amplify-space-large: 2rem;
  --amplify-border-widths-small: 2px;
  --amplify-border-widths-medium: 4px;
  --amplify-border-widths-large: 8px;
  
}

[data-amplify-authenticator] [data-amplify-router] {
  border-radius: 10px;
  box-shadow: 0 12px 40px rgba(0,0,0,0.12);
}

iframe#webpack-dev-server-client-overlay{display:none!important};

.App {
  text-align: center;
  font-family: Ginora !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.lighterText,
.MuiListItemIcon-root.lighterText {
  /*color: #9EADB0;*/
  color: #C6E38F;
}

a {
  text-decoration: none;
}

.primaryColor,
.MuiListItemIcon-root.primaryColor {
  color: #70942c;
}

.secondaryColor,
.MuiListItemIcon-root.secondaryColor {
  color: #00000099;
}

.removeUppercase,
button.removeUppercase,
span.removeUppercase,
a.removeUppercase {
  text-transform: none;
}

form .formField.MuiFormControl-fullWidth,
form button.formField,
form label.formField,
form div.formField {
  margin-left: 0px;
  margin-right: 0px; 
}

.mt10 {
  margin-top: 10px;
}

.fullWidthStepper .MuiMobileStepper-progress {
  width: 100%;
}

.square:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.hidden,
.MuiFormControl-root.hidden,
.MuiStack-root.hidden {
  display: none;
}

th {
  font-family:
    'Effra-Semi-Bold',
    'Roboto',
    '"Helvetica Neue"',
    'Arial' !important;
  font-weight: 500;
}

.MuiTableRow-hover {
  cursor: pointer
}

.noTopBottomPad {
  padding-top: 0px;
  padding-bottom: 0px;
}

main.css-fxbtpg {
  background-color: #F3F7FA;
}

.imgContain.MuiCardMedia-img {
  object-fit: contain;
}

button.surveyorReferralButton:hover {
  background-color: #4e671e;
}

.dtlKip.file-upload {
  max-width: none;
  /*border-color: #70942c;*/
}

.imagePreview .MuiImageListItemBar-titleWrap {
  display: none;
}

.imagePreview .MuiImageListItemBar-actionIcon {
  width: 100%;
}
.temp{
  display: block;
}

#website-mobile-menu .MuiDrawer-paper {
  margin-top: 60px;
  width:100%;
  border-left: 0;
}

#website-mobile-menu Button {
  width:100%;
}

#website-right-nav-menu .MuiDrawer-paper {
  margin-top: 64px;
  height: auto;
}

#website-right-nav-menu .MuiModal-backdrop {
  background-color: transparent;
}

.mapTooltip .MuiTooltip-arrow::before,
.customTooltip .MuiTooltip-arrow::before {
  box-shadow: 0px 1px 0px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 1px 1px 3px 0px rgba(0,0,0,0.12);
}

.mapTooltip .MuiTooltip-arrow, 
.customTooltip .MuiTooltip-arrow {
  color: #fff;
}

label.guZdik {
  max-width: 100%;
}

.noGutter .MuiStep-root:first-child {
    padding-left: 0px
}

.noGutter .MuiStep-root:last-child {
    padding-right: 0px
}

#admin-menu-divider .MuiDivider-wrapper{
    padding-left: 3px;
    padding-right: 3px;
}

.hoverListChild {
    visibility: hidden;
}

.hoverList:hover .hoverListChild {
    visibility: visible;
}

.recharts-wrapper * { outline: none; }

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .ql-video {
    width: 853px;
    height: 480px;
  }