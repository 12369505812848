#child-component {
    opacity: 0;
}

.elementToFadeOut {
    opacity: 1;
    animation: fade 0.7s forwards;
}

.elementToFadeIn {
    opacity: 0;
    animation: show 0.7s forwards;
}

@keyframes fade {
  0% { opacity: 1 }
  100% { opacity: 0 }
}

@keyframes show {
    0% { opacity: 0 }
    100% { opacity: 1 }
  }


.outer-container {
    margin: 56px auto;
    text-align: center;
    position: relative;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loading-container {
    position: relative;
    min-height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
}

.spin-container {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 170px;
    height: 170px;
}

.spin {
    display: inline-block;
    width: 170px;
    height: 170px;
    border: 3px solid rgba(151,195,118, 0.3);
    border-radius: 50%;
    border-top-color: rgba(151,195,118);
    animation: loading-spin 3s ease-in-out infinite;
    -webkit-animation: loading-spin 3s ease-in-out infinite;
    -moz-animation: loading-spin 3s ease-in-out infinite;
}


@keyframes loading-spin {
    to {
        -webkit-transform: rotate(720deg);
    }
}

@-webkit-keyframes loading-spin {
    to {
        -webkit-transform: rotate(720deg);
    }
}

@-moz-keyframes loading-spin {
    to {
        -moz-transform: rotate(-720deg);
    }
}

/* .image-container {
    animation: spin 3s ease-in-out infinite;
    -webkit-animation: spin 3s ease-in-out infinite;
    -moz-animation: spin 3s ease-in-out infinite;
    width: 75px;
    height: 75px;
} */

/* img {
    width: 75px;
    height: 75px;
} */
        

/* @-moz-keyframes spin {
    100% {
        -moz-transform: rotate(-360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(-360deg);
    }
}
        
@keyframes spin {
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
} */


p {
    width: 100%;
}
