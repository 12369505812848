

.parser-display p {
    padding: 0;
    margin: 0;
}

.parser-display img {
    max-width: 100%;
    margin: auto;
}

.parser-display a {
    color: #649504;
    font-weight: 600;
}